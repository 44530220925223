<template>
  <div id="drivers">
    <div class="flex justify-between">
      <search-title
        :placeholder="i18n.t('driver_activities.search-by-name')"
        @search="updateActivitySearchQuery"
        >{{ i18n.t("navigation.driver_activities") }}
        <div
          v-if="activities && activities.length > 0"
          class="text-xs inline md:block text-gray-600 w-auto"
        >
          <span class="hidden md:visible">Visualizzate </span>
          {{ activities.length }} Attività
        </div>
        <div v-else class="text-xs text-gray-600 w-auto">
          {{ i18n.t("driver_activities.empty") }}
        </div>
      </search-title>

      <div class="flex justify-between py-2 md:items-center">
        <button class="btn btn-primary mr-1" @click="addNewActivity">
          <div class="w-10 h-10 rounded-md flex items-center justify-center">
            <PlusIcon />
          </div>
          <div class="ml-4 mr-auto">
            <div class="font-medium">Aggiungi attività</div>
          </div>
        </button>
        <button
          class="btn btn-secondary"
          @click="onReportRequest(null, 'eyecar', null, null)"
        >
          Report attività
        </button>
      </div>
    </div>
    <div class="flex flex-wrap mt-3">
      <div class="w-full lg:w-3/4">
        <DriverActivityCard
          v-for="a in activities"
          :key="a.id"
          :activity="a"
          :active="activeActivity && a.id == activeActivity.id"
          :vehicles="all_vehicles"
          :drivers="all_drivers"
          @updated="updateActivityCollection"
          @selected="handleActivitySelect"
          @canceled="handleActivityCancel"
        ></DriverActivityCard>

        <pagination
          :meta="activities_meta"
          class="mx-20 mt-10"
          @page="selectPage"
        />
      </div>
      <div class="w-full pl-0 lg:pl-4 lg:w-1/4">
        <h2 class="mb-4">Filtra per autista</h2>
        <!-- drivers -->
        <DriverCard
          v-for="d in drivers"
          :key="d.id"
          :driver="d"
          :actions="false"
          :active="activeDriver && d.id == activeDriver.id"
          @selected="handleDriverSelect"
          @canceled="handleDriverCancel"
        ></DriverCard>
      </div>
    </div>
    <!--Report slider-->
    <ReportSlider
      :active="reportSelectorOpened"
      :save="i18n.t('global-map.report.save')"
      :cancel="i18n.t('global-map.report.cancel')"
      :selected="report_selected"
      :enabled="report_enabled"
      :type="report_type"
      @save="onReportSave"
      @close="onReportClose"
    />
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import { useI18n } from "vue3-i18n";
import { useStore } from "@/store";
import dayjs from "dayjs";
import { useCollectionSearch } from "@/composables/collection-search";
import DriverCard from "./DriverCard.vue";
import DriverActivityCard from "./DriverActivityCard.vue";
import ReportSlider from "@/components/report-slide/ReportSlider.vue";
import EventBus from "@/libs/event-bus";

import pagination from "@/components/pagination/Main.vue";

export default {
  components: { DriverCard, DriverActivityCard, ReportSlider, pagination },
  setup() {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const utc = require("dayjs/plugin/utc");
    dayjs.extend(utc);
    const i18n = useI18n();
    const store = useStore();

    const all_vehicles = ref([]);
    const all_drivers = ref([]);

    //reporting
    const report_selected = ref(["drivers.report"]);
    const report_enabled = ref(["drivers.report"]);
    const report_type = ref("drivers");
    const reportSelectorOpened = ref(false);

    const page = ref(1);

    function onReportRequest(service, type, enabled, selected) {
      console.log("onReportRequest", service, type, enabled, selected);
      report_selected.value.splice(0, report_selected.value.length);
      if (service) report_selected.value.push(...service);

      if (enabled) {
        report_enabled.value.splice(0, report_enabled.value.length);
        report_enabled.value.push(...enabled);
      }
      if (enabled) {
        report_enabled.value.splice(0, report_enabled.value.length);
        report_enabled.value.push(...enabled);
      }
      report_type.value = type;
      reportSelectorOpened.value = true;
    }

    function onReportClose() {
      reportSelectorOpened.value = false;
    }

    function onReportSave(services, from, to, type) {
      console.log("onReportSave", services, from, to, type);
      store.dispatch("globalMap/requestReport", {
        services: services.join(),
        from: dayjs(from).format(),
        to: dayjs(to).format(),
        type: "drivers",
      });
      EventBus.emit("notification:show", {
        type: "success",
        title: i18n.t("reports.notify.submitted"),
      });
    }

    const {
      updateSearchQuery,
      collectionToFilter,
      filteredCollection: drivers,
    } = useCollectionSearch(["name", "surname", "license", "note"]);

    const {
      updateSearchQuery: updateActivitySearchQuery,
      collectionToFilter: collectionActivityToFilter,
      filteredCollection: activities,
      externalFilter: driverFilter,
    } = useCollectionSearch(["vehicle.plate"]);

    driverFilter.value = function (collection) {
      if (activeDriver.value) {
        return collection.filter((a) => a.driver.id == activeDriver.value.id);
      } else return collection;
    };

    collectionToFilter.value = [];

    async function fetchVehicles() {
      const response = await store.dispatch("vehicles/getCollection");
      console.log("Response: ", response);

      all_vehicles.value = response.validated.filter((d) => d.active == 1);
    }

    async function fetchDrivers() {
      const response = await store.dispatch("drivers/getCollection");
      console.log("Response: ", response);
      collectionToFilter.value = response.validated.filter(
        (d) => d.active == 1,
      );
      all_drivers.value = response.validated.filter((d) => d.active == 1);
    }

    async function fetchDriverActivities() {
      const response = await store.dispatch("driver_activities/getCollection", {
        page: page.value,
      });
      console.log("Response activities_collection: ", response);
      collectionActivityToFilter.value = response.validated;
      if (activeDriver.value)
        collectionActivityToFilter.value =
          collectionActivityToFilter.value.filter(
            (a) => a.driver.id == activeDriver.value.id,
          );
      collectionActivityToFilter.value =
        collectionActivityToFilter.value.filter((d) => d.id);
    }

    function selectPage(p) {
      page.value = p;
    }

    fetchDrivers();
    fetchDriverActivities();

    // active driver
    const activeDriver = ref(null);

    function handleDriverSelect(driver) {
      selectDriver(driver);
    }

    function handleDriverCancel() {
      activeDriver.value = null;
      collectionToFilter.value = collectionToFilter.value.filter((d) => d.id);
    }

    // active activity
    const activeActivity = ref(null);

    function handleActivitySelect(activity) {
      selectActivity(activity);
    }

    function handleActivityCancel() {
      activeActivity.value = null;
      collectionActivityToFilter.value =
        collectionActivityToFilter.value.filter((d) => d.id);
    }

    function selectDriver(driver) {
      if (activeDriver.value != null && activeDriver.value.id == driver.id) {
        activeDriver.value = null;
      } else {
        activeDriver.value = driver;
      }
      fetchDriverActivities();
    }

    function selectActivity(activity) {
      activeActivity.value = activity;
    }

    // new driver
    function addNewDriver() {
      if (activeDriver.value && !activeDriver.value.id) return;
      collectionToFilter.value = [
        {
          name: i18n.t("drivers.new"),
          surname: null,
          license: null,
          license_expiration_date: null,
          ibutton_id: null,
          phone_number: null,
          note: null,
        },
        ...collectionToFilter.value,
      ];
      activeDriver.value = collectionToFilter.value[0];
    }

    // new driver
    function addNewActivity() {
      if (activeActivity.value && !activeActivity.value.id) return;
      collectionActivityToFilter.value = [
        {
          id: null,
          driver_id: null,
          vehicle_id: null,
          pariod_from: null,
          pariod_to: null,
        },
        ...collectionActivityToFilter.value,
      ];
      activeActivity.value = collectionActivityToFilter.value[0];
    }

    onMounted(async () => {
      await fetchDrivers();
      await fetchVehicles();
      await fetchDriverActivities();
    });

    function updateCollection(c) {
      collectionToFilter.value = c;
    }

    function updateActivityCollection() {
      fetchDriverActivities();
    }

    return {
      i18n,
      dayjs,

      // fetch drivers
      all_drivers,
      all_vehicles,
      drivers,
      activities,

      updateCollection,
      updateActivityCollection,

      // active activity
      activeActivity,
      handleActivitySelect,
      handleActivityCancel,

      // active driver
      activeDriver,
      handleDriverSelect,
      handleDriverCancel,

      // new driver
      addNewDriver,
      addNewActivity,

      updateSearchQuery,
      updateActivitySearchQuery,

      selectPage,

      reportSelectorOpened,
      onReportRequest,
      report_selected,
      report_enabled,
      report_type,
      onReportSave,
      onReportClose,
    };
  },
};
</script>
