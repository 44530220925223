<template>
  <div id="customers-index">
    <div class="flex justify-between py-2">
      <search-title class="flex-1" @search="updateSearchQuery">{{
        i18n.t("navigation.customers")
      }}</search-title>

      <div class="flex items-center">
        <div
          v-if="!customers_meta || customers_meta.total > 0"
          class="hidden md:block text-gray-600"
        >
          {{ customers_meta?.count }} / {{ customers_meta?.total }}
          {{ i18n.t("general.results") }}
        </div>
        <h2 v-else>
          {{ i18n.t("customers.empty") }}
        </h2>
      </div>
    </div>

    <!-- new customers-->
    <button
      v-if="customers_meta && max_users > customers_meta.total"
      class="btn btn-primary w-full mb-6"
      @click="addNewCustomer"
    >
      <div class="w-10 h-10 rounded-md flex items-center justify-center">
        <PlusIcon />
      </div>
      <div class="ml-4 mr-auto">
        <div class="font-medium">{{ i18n.t("customers.add") }}</div>
      </div>
    </button>
    <div
      v-else
      class="flex items-center bg-blue-500 text-white text-sm font-bold px-4 py-3 my-8"
      role="alert"
    >
      <svg
        class="fill-current w-4 h-4 mr-2"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
      >
        <path
          d="M12.432 0c1.34 0 2.01.912 2.01 1.957 0 1.305-1.164 2.512-2.679 2.512-1.269 0-2.009-.75-1.974-1.99C9.789 1.436 10.67 0 12.432 0zM8.309 20c-1.058 0-1.833-.652-1.093-3.524l1.214-5.092c.211-.814.246-1.141 0-1.141-.317 0-1.689.562-2.502 1.117l-.528-.88c2.572-2.186 5.531-3.467 6.801-3.467 1.057 0 1.233 1.273.705 3.23l-1.391 5.352c-.246.945-.141 1.271.106 1.271.317 0 1.357-.392 2.379-1.207l.6.814C12.098 19.02 9.365 20 8.309 20z"
        />
      </svg>
      <p>{{ i18n.t("customers.max_customers_error") }}</p>
    </div>
    <div
      class="grid md:grid-cols-2 lg:grid-cols-3 gap-6 max-w-screen-2xl mx-auto"
    >
      <!-- customers -->
      <CustomerCard
        v-for="customer in filteredCustomers"
        :key="customer.id"
        :active="activeCustomer && customer.id == activeCustomer.id"
        :customer="customer"
        @updated="updateCustomers"
        @canceled="handleCustomerCancel"
      />
    </div>

    <pagination :meta="customers_meta" class="mx-20 mt-10" @page="selectPage" />
  </div>
</template>

<script>
import { ref, computed } from "vue";
import { useI18n } from "vue3-i18n";
import { useStore } from "vuex";
import CustomerCard from "./CustomerCard.vue";
import { useCollectionSearch } from "@/composables/collection-search";

export default {
  components: { CustomerCard },
  setup() {
    const i18n = useI18n();
    const store = useStore();
    const {
      updateSearchQuery,
      collectionToFilter,
      filteredCollection: filteredCustomers,
    } = useCollectionSearch([
      "name",
      "zip_code",
      "vat_id",
      "region",
      "email",
      "phone",
    ]);

    const customer = ref(store.getters["auth/customer"]);

    const max_users_conf = customer.value.configurations.find(function (conf) {
      return conf.parameter == "max_users";
    });
    const max_users = max_users_conf !== undefined ? max_users_conf.value : 50;

    function updateCustomers(c) {
      collectionToFilter.value = c;
    }

    const customers_meta = computed(
      () => store.getters["customers/collection_meta"],
    );

    // active customer
    const activeCustomer = ref(null);

    function handleCustomerSelect(customer) {
      selectCustomer(customer);
    }

    function handleCustomerCancel() {
      activeCustomer.value = null;
      collectionToFilter.value = collectionToFilter.value.filter((c) => c.id);
    }

    function selectCustomer(customer) {
      activeCustomer.value = customer;
    }

    // new customer
    function addNewCustomer() {
      if (activeCustomer.value && !activeCustomer.value.id) return;
      collectionToFilter.value = [
        {
          vat_id: null,
          name: null,
          address: null,
          city: null,
          zip_code: null,
          region: null,
          phone: null,
          email: null,
        },
        ...collectionToFilter.value,
      ];
      activeCustomer.value = collectionToFilter.value[0];
    }

    async function selectPage(page) {
      const result = await store.dispatch("customers/getCollection", {
        page: page,
      });
      collectionToFilter.value = result.validated;
    }
    selectPage(1);

    return {
      i18n,
      updateSearchQuery,
      filteredCustomers,
      max_users,
      customers_meta,
      //customers
      updateCustomers,
      activeCustomer,
      handleCustomerSelect,
      handleCustomerCancel,

      // new customer
      addNewCustomer,
    };
  },
};
</script>

<style></style>
